
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 60px 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0px;
    }
    @include media-breakpoint-up(xl) {
      padding: 140px 0px;
    }
    &.less-pad {
        padding: 60px 0
    }
    @include media-breakpoint-up(xl) {
      &.large-pad {
        padding: 160px 0;
      }
    }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.6;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-preview-container {
    position: relative;
    padding-bottom: 40%;
    overflow: hidden;
    
  iframe,
  object,
  embed,
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

	.play-button {
    width: 70px;
		position: absolute;
		left: calc(50% - 35px);
		top: calc(50% - 35px);
		padding: 0;
		margin: 0;
		border: none;
		appearance: none;
		background: none;
		z-index: 5;
		transition: .8s ease;
    @include media-breakpoint-up(lg) {
      width: 218px;
      left: calc(50% - 114px);
      top: calc(50% - 114px);
    }

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&:focus {
			outline: 0;
		}

		img {
			width: 100%;
		}
	}
}

.max-width-image {
    width: 100%;
    max-width: 400px;
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }
}
.min-width-img-xl {
  @include media-breakpoint-up(xl) {
    min-width: 500px;
  }
  @include media-breakpoint-up(xml) {
    min-width: 600px;
  }
}

.container-fluid {
    max-width: 1750px;
}

.color {
    &-primary {
        color: $primary;
    }
    &-secondary {
        color: $secondary;
    }
    &-yellow {
      color:$color-yellow;
    }
    &-light-gray {
      color:$color-light-gray;
    }
}
img {
  width: 100%;
}
strong {
  font-family: $headings-font;
  font-weight: normal;
}
.bg-gray {
  background-color: $color-gray;
}
.bg-light-blue {
  background-color: $color-light-blue ;
}
.bg-white {
  box-shadow: -2px 1.5px 10px 10px rgba(185, 185, 185, 0.16);
  @include media-breakpoint-up(lg) {
    box-shadow: -4px 3px 36px 6px rgba(185, 185, 185, 0.36);
  }
}