.flip-clock {
	text-align: center;
	perspective: 800px;
	margin: 20px auto;
	
}	
.flip-clock *,
.flip-clock *:before,
.flip-clock *:after {
	box-sizing: border-box;
}

.flip-clock__piece {
	display: inline-block;
	margin: 0 10px;
}

.flip-clock__slot {
	font-size: 1.5rem;
	color: $primary;
	font-family: $headings-font;
}

.card {
	display: block;
	position: relative; 
	padding-bottom: 0.72em;
	line-height: 0.95;
	font-family: $headings-font;
	font-size: 5rem;
	box-shadow: -7px 5px 30px 6px rgba(185, 185, 185, 0.36);
	border-radius: 2rem;
}

.card__top,
.card__bottom,
.card__back::before,
.card__back::after {
	display: block;
	height: 0.72em;
	color: #e56b66;
	background: white;
	padding: 0.25em 0.25em;
	border-radius: 0.15em 0.15em 0 0;
	backface-visiblity: hidden;
	transform-style: preserve-3d;
	width: 2em;
	transform: translateZ(0);
}

.card__bottom { 
	color: $primary;
	position: absolute;
	top: 50%;
	left: 0;
	background: white; 
	border-radius: 0 0 0.15em 0.15em; 
	pointer-events: none;
	overflow: hidden;
}

.card__bottom::after {
	display: block;
	margin-top: -0.72em;;
}

.card__back::before,
.card__bottom::after {
	content: attr(data-value);
}

.card__back {
	position: absolute;
	top: 0;
	height: 100%;
	left: 0%;
	pointer-events: none;
}

.card__back::before {
	position: relative;
	z-index: -1;
	overflow: hidden;
}

.flip .card__back::before {
	animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
	animation-fill-mode: both;
	transform-origin: center bottom;
}

.flip .card__back .card__bottom {
	transform-origin: center top;
	animation-fill-mode: both;
	animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);// 0.3s; 
}

@keyframes flipTop {
	0% {
		transform: rotateX(0deg);
		z-index: 2;
	}
	0%, 99% {
		opacity: 0.99;
	}
	100% {
		transform: rotateX(-90deg);
		opacity: 0;
	}
}

@keyframes flipBottom {
	0%, 50% {
		z-index: -1;
		transform: rotateX(90deg);
		opacity: 0;
	}
	51% {
		opacity: 0.99;
	}
	100% {
		opacity: 0.99;
		transform: rotateX(0deg);
		z-index: 5;
	}
}