header {

    padding: 15px;
    background-color: transparent;
    
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: $headerbg;
        transition: background-color 0.5s ease-in-out;
    }

    // First section extra padding to accommodate header
    + section {
        padding-top: $headerheight;
    }

}

.header-logo img {
    transition: filter 0.2s ease-in-out, width 0.2s ease-in-out;

    // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
    // filter: invert(1);

    // Default to small scrolled size on mobile 
    width: $header-scrolled-width;

    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
        width: $header-starting-width;
    }
    .scrolled & {
        filter: none;
        
        // Turn on the header resize on desktop
        @include media-breakpoint-up(md) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2; 
    text-decoration: none !important;
    .phone-icon {
        img,
        svg {
            width: 35px;
            @include media-breakpoint-up(md) {
                width: 47px;
            }
        }
    }

    span {
        font-size: 2rem;
    }
    em {
        font-size: 2.5rem;
        font-family: $headings-font;
        font-style: normal;
    }

} 