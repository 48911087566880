// --------------------
// VARIABLES
//---------------------

$headerheight   : 150px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 240px;
$header-scrolled-width: 150px;

// Colors
$body-color		: #747474;
$primary        : #d9251e;
$secondary      : #363637;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$headerbg       : black; // Header scrolled background color.


$color-yellow: #faf504;
$color-blue: #05a7e3;
$color-light-blue: #e3f6f7;
$color-gray: #f8f8f8;
$color-light-gray: #d3cdca;

// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  );
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
  xl: 1140px,
  xml: 1450px,
  xxl: 1600px,
);
