section {
    &#hero {
        padding-top: 120px;
        @include media-breakpoint-up(xl) {
            padding: 110px 0;
        }

        .video-preview-container {
            background: rgba(0,0,0,.4);
            padding-bottom: 56%;
            margin-bottom: 30px;
        }
    }
    &#section-2 {
        img {
            max-width: 50px;
            border-radius: 10px;
        }

        @include media-breakpoint-down(md) {
            .box {
                max-width: 200px;
            }
            .slider .slide p {
                max-width: 100%;
            }
        }
    }

    &#section-3,
    &#section-7,
    &#section-9 {
        figure {
            padding-bottom: 80px;
            .description {
                padding: 2.5rem 1.5rem;
                max-width: 90%;
                bottom: 0;
                z-index: 99;
                left: 5%;
                @include media-breakpoint-up(lg) {
                    left: 10%;
                    max-width: 80%;
                    padding: 3.5rem;
                }
            }
        }
    }

    &#section-3 {
        figure {
            @include media-breakpoint-up(xml) {
                padding: 20px 20px 80px;
            }
            .border-left-red strong {
                border-left: 4px solid #d9251e;
                padding-left: 20px;
                max-width: 350px;
            }
        }
    }

    &#section-4 {
        @include media-breakpoint-down(md) {
            padding-top: 100px;
        }
        p {
            color: black;
            @include media-breakpoint-up(md) {
                color: $body-color;
                font-size: 2.25rem;
            }
        }
        .background-image-container img {
            object-position: left center;
            @include media-breakpoint-up(md) {
                object-position: center center;
            }
        }
        .icon-star {
            max-width: 170px;
        }
        blockquote {
            font-family: $headings-font;
            font-size: 16.5rem;
            line-height: 0.2;
        }
    }
    &#section-5,
    &#section-10,
    &#steps-block {
        .icon-header {
            max-width: 100px;
        }
    }
    &#section-6 {
        .icon-header {
            max-width: 136px;
        }
    }

    &#section-7, 
    &#section-9 {
        figure {
            .description {
                max-width: 90%;
                left: 5%;
            }
        }
    }

    &#section-7 {
        h2 {
            @include media-breakpoint-up(xl) {
                min-width: 570px;
            }
        }
    }

    &#table {
        .icon-header {
            max-width: 118px;
        }
    }
    
    &#section-11 {
        .icon-header {
            max-width: 220px;
            @include media-breakpoint-up(md) {
                max-width: 368px;
            }
        }
    }

    &#last-section {
        padding-bottom: 44%;
        .background-image-container img {
            background-color: white;
            object-fit: contain;
            object-position: center bottom;
        }
        .btn-section {
            max-width: 100%;
        }
        .description {
            font-size: 1.55rem;
        }
    }
}