// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'Fredoka One';
    src: url('../../fonts/FredokaOne-Regular.woff2') format('woff2'),
    url('../../fonts/FredokaOne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../../fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../../fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: url('../../fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../../fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$headings-font: 'Fredoka One', sans-serif;
$body-font: 'Gilroy-Regular', sans-serif;
$bold-font: 'Gilroy-Bold', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 1.9rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: 1.5;
    font-family: $body-font;
}

p {
    margin-bottom: 3rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 4rem;
    font-family: $headings-font;
    font-weight: normal;
    color: $secondary;
    text-transform: capitalize;
}

h1, .h1 {
    font-size: 3.5rem;
    @include media-breakpoint-up(md) {
        font-size: 4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 5rem;
    }
}

h2, .h2 {
    font-size: 3rem;
    @include media-breakpoint-up(md) {
        font-size: 3.6rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 4.25rem;
    }
}

h3, .h3 {
    font-size: 2.5rem;
    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }
}

h4, .h4 {
    font-size: 2.25rem;
}

h5, .h5 {
    font-size: 2rem;
}

h6, .h6 {
    font-size: 1.8rem;
}

// Hero Specific Font Sizes
.eyebrow {
    font-size: 2rem;
    letter-spacing: 1.2px;
}
.subheadline {

}