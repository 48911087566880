body.ebook-page {
    #hero {
      @include media-breakpoint-down(md) {
        padding-top: 120px;

        .book-cover {
          max-width: 200px;
        }

      }


    }

}