// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 50px;
    margin-bottom: 12px;
    font-size: 2rem;
    line-height: 1;
    text-transform: capitalize;
    padding: 25px 3px;
    white-space: normal;
    background: $primary;
    font-family: $headings-font;
    color: #fff;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.17);
    transition: background-color .4s ease-in-out;
    outline: none !important;
    box-shadow: none !important;
    @include media-breakpoint-up(md) {
        letter-spacing: 2px;
        font-size: 2.25rem;
        padding: 30px 5px;
    }
    &:hover,
    &:focus,
    &:active {
        background: rgba($primary, .8);
    }
}

.btn-section {
    p {
        width: 90%;
        margin: 0 auto;
        font-size: 1.55rem;
    }
}