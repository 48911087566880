#steps-block {
    .step {
        border-radius: 4rem;
        padding: 3.5rem 2rem 4rem 2rem;
        @include media-breakpoint-up(xl) {
            padding: 6.5rem 3rem 4rem 3rem;
        }
        &-number {
            background: $color-blue;
            color: #fff;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 50%;
            @include media-breakpoint-up(lg) {
                width: 100px;
                height: 100px;
                line-height: 100px;
            }
        }
    }
}