/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    min-height: 1px;
    outline: none !important;
    box-shadow: none !important;
    .video-container {
        max-width: 90%;
        @include media-breakpoint-up(lg) {
            max-width: 70%;
        }
    }
    p {
        max-width: 70%;
        margin: 0 auto 1.8rem;
        @include media-breakpoint-up(lg) {
            font-size: 2.5rem;
        }
        &.sub-title {
            @include media-breakpoint-up(lg) {
                font-size: 2.25rem;
            }
        }
    }
    h4 {
        font-family: $bold-font;
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


// Custom Slick Styles

// Arrows
.slick-arrow {
    position: absolute;
    top: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
        top: 44%;
        width: 54px;
        height: 54px;
    }
    background: transparent;
    cursor: pointer;

    // Positions the arrow center inside the cirlce:
    svg {
        width: 25px;
        height: 25px;
        fill: white
    }
    &.prev,
    &.prev-arrow {
        left: 0;
        padding-right: 4px;
    }
    &.next,
    &.next-arrow {
        right: 0;
        padding-left: 4px;
    }
}


// Dots
.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 2rem 0 0 0;
    list-style-type: none;

    li {
        margin: 0 0.25rem;
    }

    button {
        display: block;
        width: 2rem;
        height: 2rem;
        padding: 0;
        border: 2px solid transparent;
        border-radius: 50%;
        background-color: $secondary !important;
        text-indent: -9999px;
    }

    li.slick-active button {
        background-color: $primary !important;
    }
}