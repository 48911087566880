ul, ol {
	&.unstyled-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&.discover-list li,
	&.session-list li {
		img {
			max-width: 30px;
			@include media-breakpoint-up(md) {
				max-width: 44px;
			}
		}
	}
	&.session-list li {
		border-radius: 2rem;
		padding: 3rem 2rem;
		@include media-breakpoint-up(md) {
			border-radius: 4rem;
			padding: 4rem;
		}
	}
}