form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        border: none;
        margin-bottom: 15px;
        border-radius: 50px;
        font-size: 2rem;
        line-height: 1;
        box-shadow: 0px 5.5px 11px 1.32px rgba(227, 246, 247, 0.31);
        outline: none !important;
        @include media-breakpoint-up(md) {
            padding: 28px 15px 28px 70px;
        }
    }

    textarea {
        height: 180px !important;
    }

    select {
        appearance: none;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        &.error {
            display: block;
            margin-bottom: 20px;
            margin-top: -10px;
            color: red;
            font-size: 1.9rem;
        }
        .icon {
            position: absolute;
            top: 17px;
            left: 15px;
            background-size: 80%;
            width: 25px;
            height: 25px;
            z-index: 999;
            margin: auto;
            @include media-breakpoint-up(md) {
                left: 25px;
                top: 25px;
                width: 33px;
                height: 33px;
            }
            &.icon-phone {
                width: 18px;
                left: 20px;
                top: 16px;
                @include media-breakpoint-up(md) {
                    top: 20px;
                    width: 21px;
                    left: 32px;
                }
            }
        }
    }
}

.form-group {
    width: 100%;
}