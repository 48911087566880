
.table,
tbody {
	border-radius: 25px;
	display: block;
	@include media-breakpoint-up(md) {
		border-radius: 55px;
	}
}
.table {
	border: 18px solid rgba(white, .5);
	box-shadow: 0px 3px 106px 0px rgba(227, 246, 247, 0.63);
	border-radius: 3rem;
	@include media-breakpoint-up(md) {
		border-radius: 5rem;
	}
	tbody {
		border-radius: 1.5rem;
		@include media-breakpoint-up(md) {
			border-radius: 3.5rem;
		}
	}

	tr {
		display: block;
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		padding: 10px 0;
		th,
		td {
			padding: 5px 10px;
			vertical-align: middle;
			border: none;
			&.text-left p {
				color: $secondary;
				font-family: $headings-font;
			}
		}

		th {
			padding: 10px 0;
			.table-img {
				border-radius: 10px;
			}
		}
		td {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		@include media-breakpoint-up(md) {
			display: table-row;
			border-bottom: 0;
			padding: 0;
			th, td {
				width: 20%;
				padding: 0 20px;
				&.first-column {
					width: 40%;
				}
				&.active-cell {
					position: relative;
					background-color: $color-blue;
				}
				&.text-left p {
					color: $body-color;
					font-family: $body-font;
				}
			}
			th {
				padding: 10px;
			}
			td {
				border-bottom: 1px solid rgba(0, 0, 0, 0.09);
				display: table-cell;
				justify-content: unset;
			}
			th.active-cell:before,
			&:last-child td.active-cell:before {
				content: "";
				position: absolute;
				width: 100%;
				background-color: $color-blue;
				left: 0;
				height: 40px;
			}
			
			th.active-cell:before {
				top:-30px;
				border-radius: 10px 10px 0 0;
			}
	
			&:last-child td.active-cell:before {
				bottom: -30px;
				border-radius: 0 0 10px 10px;
			}
		}
	}
	tr:last-child,
	tr:last-child td,
	tr td.active-cell {
		border-bottom: 0;
	}


	.icon-x,
	.icon-check {
		width: 20px;
		margin-right: 15px;
		@include media-breakpoint-up(md) {
			margin-right: 0;
		}
	}
	.icon-circle-check {
		width: 50px;
		@include media-breakpoint-up(md) {
			width: 62.5px;
		}
	}
}